@font-face {
    font-family: 'yg-jalnan';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JalnanGothic';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GongGothicMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

.wrap {
    font-family: "Noto Sans KR", sans-serif;
    background-color: var(--background-color);
    z-index: 999;

}

.logo__wrap {
    display: flex;
    justify-content: center;

    align-items: center;
}

.logo {
    width: 200px;
}

a {
    color: var(--button-text-color);
    text-decoration: none;
    font-family: "Noto Sans KR", sans-serif;
}

.toggleButton {
    position: absolute;
    top: 1rem;
    right: 16rem;
    padding: 0.5rem 1rem;
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 99;
    display: flex;
    align-items: center;
}

.switchWrapper {
    display: flex;
}

.instagramIcon {
    color: #e1306c;
    font-size: 24px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.instagramIcon:hover {
    transform: scale(1.2);
    color: #ff69b4;
}



header {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
}

.mode {
    color: var(--text-color) !important;
    font-family: 'GongGothicMedium' !important;
    width: 90px;
}