* {
    font-family: 'GongGothicMedium';
    color: (0, 149, 246);
}

.container {
    display: flex;
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: 600;
}

.main__wrap {
    width: 100%;
    margin-left: 5rem;
    padding: 6rem 24px 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.section {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
}


.main__wrap .title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.3rem;
    color: var(--text-color);
}

/* 설명 */

.descwrap {
    width: 100%;
    line-height: 1.5;
}

.section .descwrap__subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-color);
}

.section .descwrap__subtitle__desc {
    margin-top: 1rem;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 2;
}

.codeContainer {
    padding-bottom: 1.5rem;
    background-color: #f4f4f4;
    border-radius: 5px;
    position: relative;
    font-family: none;
}

/* 맥북 형식 */

.windowBar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0.5rem;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.red {
    background-color: #ff5f56;
}

.yellow {
    background-color: #ffbd2e;
}

.green {
    background-color: #27c93f;
}

/* 예제 이름 */

.ex__name {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--text-color);
    font-family: 'GongGothicMedium';
}

/* 복사 버튼 */
.commonbtn {
    position: relative;
}

.copyButton {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copyButton:hover {
    background-color: #555;
}

.downbtn__wrap {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.downbtn {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.downbtn:hover {
    background-color: #555;
}

/* 복사 완료 멘트 */

.copySuccess {
    margin-left: 1rem;
    color: green;
    font-weight: bold;
    position: absolute;
    top: 9px;
}

/* 실행 예제 코드 백그라운드 */

.render__code {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fafafa;
    margin-bottom: 1rem;
    font-family: none;
    color: black;
}

/* 실행 예제 제목 */

.render__name {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 2rem;
    color: var(--text-color);
}

.render__name__sub {
    color: var(--text-color);
}

/* 스켈레톤 ui */
.skeleton {
    margin-bottom: 1rem;
}

/* 다음글 이전글 */
.navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.navigationLink {
    color: #6d84c8;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 1px solid #6d84c8;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.navigationLink:hover {
    background-color: #6d84c8;
    color: #ffffff;
}

/* 추천 링크 */

.linklist {
    margin-top: 1rem;
    padding-left: 1rem;
    list-style-type: disc;
}

.linklist li {
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.linklist li a {
    color: var(--text-color);
    text-decoration: none;
}

.linklist li a:hover {
    text-decoration: underline;
}

/* 폰트 예제  */
.font {
    text-align: center;
    color: red;
}

.font2 div span {
    color: red;
}

.font2 div>span {
    color: blue;
}

.font2 h1,
p span {
    color: red;
}

.font2 {
    font-size: 14px;
}

.check {
    font-weight: 600;

}

.backgrond {
    background-color: #333;
}

.heading {
    font-size: 60px;
    text-transform: capitalize;
    text-align: center;
    color: gold;
    text-shadow: 0 0 15px white;
    ;
}

.heading2 {
    text-align: center;
    font-size: 130px;
    font-weight: bold;
    color: crimson;
    text-shadow: 5px 5px 0 yellowgreen, 10px 10px 0 #ddd;
}

/* 목록 테스트 예제 */
.list {
    padding: 0;
    line-height: 2em;
}

.list>li {
    list-style: none;
}

.list>li>b {
    background-color: #000;
    color: #fff;
    font-weight: normal;
}

.list>li ul {
    list-style: decimal;
}

.football {
    list-style: none;
    margin: 0;
    padding: 40px;
    border: 1px solid #ddd;
    width: 800px;
    overflow: hidden
}

.football li {
    float: left;
    text-align: center;
    width: 20%;
}

.football li a {
    text-decoration: none;
    color: #000;
}

.football li a:hover {
    color: royalblue;
    text-decoration: underline;
}

/* element 예제 */

.contents {
    width: 600px;
    padding: 20px;
    text-align: center;
    background-color: #82a2cc;
}

.contents__box1 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #a96d6d;
}

.contents__box2 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #9f8585;
}

.contents__box3 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #e8bcbc;
}

.contents2 {
    display: inline-flex;
    margin-right: 10px;
}

.contents2__box1 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #ff0000;
    margin-right: 10px;
}

.contents2__box2 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #ff9900;
    margin-right: 10px;
}

.contents2__box3 {
    width: 300px;
    padding: 20px;
    text-align: center;
    background-color: #26ff00bd;
}


/* 컨텐츠 더미텍스트 */
.contents__lorem {
    color: #834444;
}

/* display 예제 */

.imgcontents img {
    display: block;
}

.imgcontents2 img {
    display: lnline;
}

.imgcontents3 img {
    display: none;
}

/* float 예제 */
.floatwrap {
    width: 300px;
    height: 100px;
    background-color: #27c93f;
}

.boxs {
    width: 50px;
    height: 50px;
    background-color: yellow;
    float: left;
}

.boxs2 {
    width: 50px;
    height: 50px;
    background-color: yellow;
    margin: auto;
}

.boxs3 {
    width: 50px;
    height: 50px;
    background-color: yellow;
    float: right;
}

/* 포지셔닝 예제 */
.parent {
    position: relative;
    background-color: blue;
    width: 300px;
    height: 200px;
}

.child {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-color: red;
    color: #ddd;
}

.btn__top {
    position: fixed;
    width: 20px;
    height: 20px;
    right: 20px;
    bottom: 20px;
    background-color: aqua;
}

/* absolute 예제 */
.parent1 {
    position: relative;
    border: 2px solid red;
    width: 600px;
    height: 300px;
}

.child1 {
    position: absolute;
    background-color: gold;
    width: 200px;
    height: 100px;
}

/* Position the .child1 elements */
.child1:nth-child(1) {
    top: 0;
    left: 0;
}

.child1:nth-child(2) {
    top: 0;
    right: 0;
}

.child1:nth-child(3) {
    bottom: 0;
    right: 0;
}

.child1:nth-child(4) {
    bottom: 0;
    left: 0;
}

/* z-index */

.parents {
    height: 250px;
    position: relative;
}

.parents div {
    width: 200px;
    height: 200px;
    position: absolute;
}

.childs {
    background-color: skyblue;
    z-index: 0;
}

.childs2 {
    background-color: gold;
    z-index: 1;
    top: 10px;
    left: 10px;

}

.childs3 {
    background-color: gray;
    z-index: 2;
    top: 20px;
    left: 20px;
}

/* hover 태그 예제 */
.btn {
    background-color: #fff;
    color: #000;
    text-align: center;
}

.btn:hover {
    background-color: skyblue;
    color: #fff;
}

.div {
    background-color: #eee;
    padding: 15px;
    width: 400px;
    text-align: center;
}

.div:hover h2 {
    color: red;
}

.div:hover span {
    color: blue;
}

/* 가상클래스 first-last */
.divfirst {
    background-color: #eee;
    padding: 15px;
    width: 400px;
    text-align: center;
}

.divfirst h2:first-child {
    color: red
}

.divfirst h2:last-child {
    color: blue;
}

/* nth-child 예제 */
.div__nthcild {
    background-color: #eee;
    padding: 15px;
    width: 400px;
    text-align: center;
}

.div__nthcild h2:nth-child(1) {
    color: red;
}

.div__nthcild h2:nth-child(2) {
    color: blue;
}

.div__nthcild h2:nth-child(3) {
    color: green;
}

/* nth-of-tpye 예제 */

.div__nth__of__type {
    background-color: #eee;
    padding: 15px;
    width: 400px;
    text-align: center;
}

.div_nth__font {
    font-size: 1.5rem;
}

.div__nth__of__type h2:nth-of-type(1) {
    color: red;
}

.div__nth__of__type h2:nth-of-type(2) {
    color: blue;
}

/* focus 예제 */
.inputemail {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    outline: none;
}

.inputemail:focus {
    background-color: skyblue;
}

/* placeholder 예제 */

.inputemailex {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
    outline: none;
}

.inputemailex::placeholder {
    color: violet;
    font-style: italic;
}

/* checked 예제 */
.formex {
    border: 1px solid #ddd;
    width: 350px;
    padding: 20px;
    border-radius: 5px;
}

.formex label {
    display: inline-block;
    width: 80px;
    margin: 10px;
}

.formex input[type=text] {
    border: 2px solid red;
}

.formex input[type=password] {
    border: 2px solid blue;
}

.formex input[type=email] {
    border: 2px solid gold;
}

/* before & after 예제01 */

.bfex01 {
    font-weight: normal;
    position: relative;
}

.bfex01::before {
    content: '✌️';
}

.bfex01::after {
    content: '#01';
    background-color: crimson;
    color: #fff;
    font-size: 18px;
    padding: 0 5px;
    border-radius: 3px;
    margin-left: 10px;
    position: absolute;
}

/* before & after 예제02 */

.bfex02 {
    list-style: none;
    padding: 0;
    line-height: 2rem;
}

.bfex02 li::before {
    content: '❌';
    color: crimson;
}

.bfex02 .bfex02__active::after {
    content: 'new';
    background-color: royalblue;
    color: #fff;
    font-size: 12px;
    margin-left: 5px;
    padding: 0 3px;
    border-radius: 3px;
}

/* before & after 예제03 */



.bf03 {
    width: 600px;
    height: 400px;
    background-size: cover;
    position: relative;
    background-image: url(/public/images/flower.jpg);
}

.bf03::before {
    content: '';
    background-color: rgba(10, 0, 255, 0.34);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* display : flex 예제 */
.flex__wrap {
    display: flex;
    width: 100%;
    height: 100px;
    position: relative;
    justify-content: center;
    align-items: center;
}

.flexex01 {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #26ff00bd;
}