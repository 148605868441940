@font-face {
    font-family: 'yg-jalnan';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'S-CoreDream-3Light';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'S-CoreDream-3Light';
}

.container {
    display: flex;
    height: 100vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: 600;
    overflow: hidden;
}

.main__wrap {
    height: 100%;
    width: 100%;
    z-index: 0;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.section {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.section h2 {
    font-size: 2rem;
    color: var(--text-color);
    margin-bottom: 10px;
}

.section p {
    line-height: 1.6;
    font-size: 1.1rem;
    color: var(--text-color);
}

code {
    background-color: var(--code-background-color);
    padding: 0px 4px;
    border-radius: 4px;
}

.section .main__start {
    font-size: 1.5rem;
    color: var(--text-color);
    position: relative;
    font-family: 'GongGothicMedium';
    padding: 15px 60px;
    border: 3px solid var(--button-border-color);
    border-radius: 100px;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    cursor: pointer;
    z-index: 1;
}

.section .main__start:hover {
    background-color: var(--button-hover-background-color);
    color: var(--button-hover-text-color);
    border: 3px solid var(--button-hover-border-color);
    transition: 0.5s ease-out;
}


@media (max-width: 480px) {
    .container {
        display: flex;
        height: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--background-color);
        color: var(--text-color);
        font-weight: 600;
        overflow: hidden;
        margin-top: 5rem;
    }
}