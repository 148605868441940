/* 공통 스타일 */
:root {
    --background-color: #fff;
    --text-color: #000;
    --code-background-color: #eaeaea;
    --button-background-color: #333;
    --button-text-color: #fff;
    --button-border-color: #333;
    --button-hover-background-color: #a19c9c;
    --button-hover-text-color: #ffffff;
    --button-hover-border-color: #a19c9c;

}

/* 다크 모드 스타일 */
:root.dark {
    --background-color: #333;
    --text-color: #fff;
    --code-background-color: #444;
    --button-background-color: #fff;
    --button-text-color: #333;
    --button-border-color: #fff;
    --button-hover-background-color: #666;
    --button-hover-text-color: #ffffff;
    --button-hover-border-color: #666;
    --border-color: 1px solid ##fafafa;
}

/* 라이트 모드 스타일 */
:root.light {
    --background-color: #fff;
    --text-color: #000;
    --code-background-color: #eaeaea;
    --button-background-color: #333;
    --button-text-color: #fff;
    --button-border-color: #333;
    --button-hover-background-color: #a19c9c;
    --button-hover-text-color: #ffffff;
    --button-hover-border-color: #a19c9c;
    --border-color: 1px solid ##fafafa;
}