* {
    font-family: 'GongGothicMedium', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    /* Change direction on small screens */
    width: 100%;
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: 600;
    padding: 1rem;
}

.main__wrap {
    width: 100%;
    margin: 0 auto;
    padding: 6rem 1rem 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.section {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.3rem;
    color: var(--text-color);
}

.descwrap {
    width: 100%;
    line-height: 1.5;
}

.descwrap__subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-color);
}

.descwrap__subtitle__desc {
    margin-top: 1rem;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 2;
}

.codeContainer {
    padding-bottom: 1.5rem;
    background-color: #f4f4f4;
    border-radius: 5px;
    position: relative;
    font-family: inherit;
}

.windowBar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #e0e0e0;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0.5rem;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.red {
    background-color: #ff5f56;
}

.yellow {
    background-color: #ffbd2e;
}

.green {
    background-color: #27c93f;
}

.ex__name {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.copyButton {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copyButton:hover {
    background-color: #555;
}

.copySuccess {
    margin-left: 1rem;
    color: green;
    font-weight: bold;
}

.render__code {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fafafa;
    margin-bottom: 1rem;
    font-family: none;
    color: black;
}

.render__name {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 2rem;
    color: var(--text-color) !important;
}

.skeleton {
    margin-bottom: 1rem;
}

.itag {
    font-style: italic;
}

.big {
    font-size: larger;
}

.small {
    font-size: smaller;
}

.ollist {
    list-style: decimal;
    padding: 1rem;
}

.ollist2 {
    list-style: upper-alpha;
    padding: 1rem;
}

.ullist {
    list-style-type: disc;
    padding: 1rem;
}

.ullist2 {
    list-style: circle;
    padding: 1rem;
}

.tabletag {
    border: 1px solid #000;
    border-collapse: collapse;
    width: 100%;
}

.tabletag thead {
    border: 1px solid #000;
}

.tabletag th,
.tabletag td {
    border: 1px solid #000;
    padding: 0.5rem;
    text-align: left;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.navigationLink {
    color: #6d84c8;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: 1px solid #6d84c8;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.navigationLink:hover {
    background-color: #6d84c8;
    color: #ffffff;
}

.textarea {
    display: flex;
    flex-direction: column;
}

.font {
    font-family: inherit;
}


@media (min-width: 768px) {
    .container {
        flex-direction: row;
        padding: 2rem;
    }

    .main__wrap {
        padding: 6rem 24px 1.5rem;

    }

    .codeContainer {
        padding-bottom: 2rem;
    }
}

@media (min-width: 1024px) {
    .container {
        padding: 3rem;
    }

    .title {
        font-size: 2.5rem;
    }

    .descwrap__subtitle {
        font-size: 1.5rem;
    }

    .descwrap__subtitle__desc {
        font-size: 1.25rem;
    }
}